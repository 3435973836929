angular.module('fingerink')
    .service('dashboardModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                templateUrl: 'web/main/dashboard/dashboardModal.tpl.html',
                controller: 'dashboardModalCtrl',
                controllerAs: 'controller',
                animation: false,
                backdrop: 'static',
                keyboard: false
            });

            return this.modalInstance;
        };

    })
    .controller('dashboardModalCtrl', function viewSignatureModalCtrl($scope, $uibModalInstance, session, companyService, swalService, fingerinkSession) {
        var that = this;

        that.company = session.get().user.company;
        that.update = () => {
            that.loading = true;
            companyService.updateEmpresa(that.company).then(
                () => {
                    that.loading = false;
                    swalService.basicSwal('¡Correcto!', 'Se han editado los datos de usuario para que los cambios sean visibles, por favor, recarga la página.', 'success');
                    $uibModalInstance.close();
                    fingerinkSession.renoveToken();
                },
                () => {
                    that.loading = false;
                    fingerinkSession.renoveToken();
                    swalService.basicSwal('Algo ha ido mal', 'Ha habido un error actualizando los datos de la empresa', 'error');
                }
            );
        };
    });
